<template>
  <ClientOnly>
    <div v-if="null === products" class="slider-loader">
      <span class="ui-loader" />
    </div>
    <ProductsSlider
      v-else-if="products.length > 0"
      :title="_T('@Products viewed')"
      :products="products"
    />
  </ClientOnly>
</template>

<script setup>
import ProductsSlider from "~/modules/shared/sliders/ProductsSlider.vue";
import { useAPI } from "~/uses/useMyFetch";
import { Product } from "~/models/product.model";

const products = ref(null);

onMounted(() => {
  useAPI("/catalog/product/list/seen", {
    params: {
      limit: 15,
    },
  }).then((data) => {
    products.value = data.map(({ product }) => new Product(product));
  });
});
</script>

<style lang="scss" scoped>
.slider-loader {
  @include flex-container(row, center, center);
}
</style>
